import React, { useState, useRef } from "react";
import {
    Dialog,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    Grid,
    Box,
} from "@material-ui/core";
import { MdClose, MdUpload, MdOutlineDelete } from "react-icons/md";
import Swal from "sweetalert2";
import Button from '../CustomButtons/Button';

const UploadMedia = ({ open, onClose, onUpload }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const dropZoneRef = useRef();

    const handleFileChange = async (e) => {
        const files = Array.from(e.target.files);
        processFiles(files);
    };

    const processFiles = (files) => {
        const validFiles = files.filter((file) => file.size <= 5 * 1024 * 1024); // Limite de 5MB
        const invalidFiles = files.filter((file) => file.size > 5 * 1024 * 1024);

        if (invalidFiles.length) {
            Swal.fire({
                icon: "error",
                title: "Erro de Upload",
                text: `Alguns arquivos excederam o limite de 5MB e foram ignorados.`,
            });
        }

        const previews = validFiles.map((file) => ({
            file,
            preview: file.type.startsWith("image/") ? URL.createObjectURL(file) : null,
        }));

        setSelectedFiles((prev) => [...prev, ...previews]);
    };

    const handlePaste = (e) => {
        const clipboardItems = e.clipboardData.items;
        const files = [];
        for (let item of clipboardItems) {
            if (item.type.startsWith("image/")) {
                files.push(item.getAsFile());
            }
        }
        processFiles(files);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        processFiles(files);
    };

    const handleRemoveFile = (index) => {
        setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
    };

    const handleUpload = async () => {
        for (const { file } of selectedFiles) {
            await onUpload(file);
        }
        setSelectedFiles([]);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogContent
                ref={dropZoneRef}
                onPaste={handlePaste}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
                style={{
                    border: "2px dashed #ccc",
                    borderRadius: "12px",
                    padding: "30px",
                    backgroundColor: "#f8f9fa",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "20px",
                    textAlign: "center",
                }}
            >
                <Typography variant="h6" style={{ color: "#000000", fontWeight: "bold" }}>
                    Arraste e solte arquivos aqui ou clique para selecionar
                </Typography>
                <input
                    type="file"
                    multiple
                    id="file-input"
                    style={{ display: "none" }}
                    accept=".pdf, .xls, .xlsx, .doc, .docx, image/*"
                    onChange={handleFileChange}
                />
                <label htmlFor="file-input">
                    <Button
                        color={'hust'}
                        variant="contained"
                        startIcon={<MdUpload />}
                        style={{
                            borderRadius: "8px",
                            padding: "10px 25px",
                            fontWeight: "bold",
                        }}
                        component="span"
                    >
                        Selecionar Arquivos
                    </Button>
                </label>

                <Grid container spacing={2} style={{ marginTop: "20px" }}>
                    {selectedFiles.map(({ preview, file }, index) => (
                        <Grid item xs={6} sm={4} md={3} key={index}>
                            <Box
                                style={{
                                    position: "relative",
                                    width: "100%",
                                    paddingTop: "100%",
                                    borderRadius: "8px",
                                    overflow: "hidden",
                                    backgroundColor: "#ffffff",
                                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {preview ? (
                                    <img
                                        src={preview}
                                        alt={file.name}
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                    />
                                ) : (
                                    <Typography
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            fontSize: "12px",
                                            textAlign: "center",
                                            color: "#6c757d",
                                        }}
                                    >
                                        {file.name}
                                    </Typography>
                                )}
                                <IconButton
                                    size="small"
                                    style={{
                                        position: "absolute",
                                        top: "5px",
                                        right: "5px",
                                        backgroundColor: "rgba(255, 0, 0, 0.8)",
                                        color: "#fff",
                                        zIndex: 10,
                                    }}
                                    onClick={() => handleRemoveFile(index)}
                                >
                                    <MdOutlineDelete />
                                </IconButton>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions style={{ padding: "20px" }}>
                <Button
                    onClick={onClose}
                    style={{
                        backgroundColor: '#939393',
                        color: '#FFF',
                        padding: '10px 15px',
                        border: 'none',
                        borderRadius: '30px',
                        cursor: 'pointer',
                    }}
                >
                    Cancelar
                </Button>

                <Button
                    onClick={handleUpload}
                    color={'hust'}
                    variant="contained"
                    disabled={!selectedFiles.length}
                    style={{
                        fontWeight: "bold",
                        borderRadius: "8px",
                        padding: "10px 25px",
                    }}
                >
                    Enviar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UploadMedia;
