import React, {Fragment, useEffect, useRef, useState} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogContent,
  Fade,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Tooltip
} from "@material-ui/core";
import {useStyles} from "../../NewChat/TabsInfo/styles";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Close} from "../../../assets/img/icons";

import Button from '../../../components/CustomButtons/Button';
import api from "../../../components/api";
import Dialog from "@material-ui/core/Dialog";
import MessageList from "../../../components/v2/Chat/MessageList";
import {Editor} from '@tinymce/tinymce-react';
import CustomInput from "../../../components/CustomInput/CustomInput";
import {GridContainer, GridItem} from "../../../components";
import {convertDate, converterTextoWpp, uploadFile, formatoDinheiro, getFotoLink, isValidDate, downloadFileAsBlob, fetchAsBlob} from "../../../components/Funcoes";
import {EditAttributesOutlined} from "@material-ui/icons";
import {
  FaCalendarPlus, FaCommentMedical, FaPlus,
  FaPlusCircle,
  FaRegBell,
  FaRegCalendarCheck,
  FaRegCalendarPlus,
  FaRegEdit, FaSave, FaPaperclip
} from "react-icons/fa";
import Swal from "sweetalert2";
import {FaCalendarCheck, FaCheck, FaMoneyBillTrendUp, FaRegStarHalfStroke} from "react-icons/fa6";
import {IoIosCloseCircle, IoMdFlash, IoMdPersonAdd} from "react-icons/io";
import {IoClose, IoPersonOutline, IoRadioButtonOffSharp, IoRadioButtonOn, IoTicketOutline} from "react-icons/io5";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import {primaryColor} from "../../../assets/jss/material-dashboard-pro-react";
import ViewUserActions from "../../Tasks/components/ViewUserActions";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import {ImRadioChecked2} from "react-icons/im";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import {MdAttachMoney, MdClose, MdOutlineAddTask} from "react-icons/md";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import {WebService} from "../../../components/WebService";
import {changeLeadValue, removeTask, TaskPriorityLabel, TaskStatusLabel, updateTask} from "../../Tasks/taskHelper";
import UploadMedia from "../../../components/UploadMedia/UploadMedia";

const useStyles1 = makeStyles({
  ...styles, modalNew: {borderRadius: 16, maxWidth: 1000},
  removeMargin: {
    margin: `0px !important`,
    '&:before': {
      border: 'none'
    }
  },
  removeUnderline: {
    // transition: '.3s',
    // '& > *::before': {
    //   border: 'none',
    //   '&:hover': {
    //     border: `1px solid ${primaryColor[0]}`
    //   }
    // }
  }
});

const defatultTaskData = {
  title: '',
  description: '',
  comments: [],
  subTasks: [
    // {id: 1, checked: true, label: 'Possuir ao menos 30 dias de conteúdos'},
    // {id: 2, checked: true, label: 'Definir temas principais sobre o conteúdo'},
    // {id: 3, checked: true, label: 'Planejamento detalhado de postagens'},
    // {id: 4, checked: false, label: 'Calendário de publicação'},
    // {id: 5, checked: false, label: 'Métricas de sucesso para acompanhamento'},
  ]
};

const TaskViewer = ({open, onClose, id}) => {
  const classes = {...useStyles(), ...useStyles1()};

  const editorRef = useRef(null);

  const [task, setTask] = useState(defatultTaskData);
  const [users, setUsers] = useState(defatultTaskData);
  const [newComment, setNewComment] = useState({open: false, text: '', mediaPreview: null});
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [viewImage, setViewImage] = useState(null);
  // const [comments, setComments] = useState([
  //   {id: 1, user: {id: 5, name: 'Italo Tavares', profilePicture: 'HfzxPxvj8rPc6Xz00nZBFG9hbM6ICFB3ICKxCe4K8Z3vgNaY5b.png'}, text: 'Show de bola!!', date: new Date()},
  //   {id: 2, user: {id: 5, name: 'Italo Tavares', profilePicture: 'HfzxPxvj8rPc6Xz00nZBFG9hbM6ICFB3ICKxCe4K8Z3vgNaY5b.png'}, text: 'Comentário antigo', date: new Date('2024-05-04 14:00:00')},
  // ]);
  const [editModeTitle, setEditModeTitle] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editValue, setEditValue] = useState({slug: '', value: 0});

  const openImageView = (imageUrl) => {
    setViewImage(imageUrl);
  };

  const closeImageView = () => {
    setViewImage(null);
  };

  const getTask = async () => {
    const {data} = await api.get(`/task/${id}`);
    setTask(data);
  };

  const getUsers = async () => {
    const { ok, usuarios } = await WebService(`/usuario/getUsuarios`);
    if (ok) setUsers(usuarios);
  };

  const updateTask = async (id, params) => {
    await api.put(`/task/${id}`, params);
    await getTask();
  };

  const removeTask = async id => {
    const {value} = await Swal.fire({
      icon: "question",
      title: 'Deseja realmente remover?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    });
    if (!value) return;
    await api.delete(`/task/${id}`);
    setTask(task => {
      const keyTask = task.subTasks.map(prop1 => prop1.id).indexOf(id);
      delete task.subTasks[keyTask];
      task.subTasks = task.subTasks.filter(el => el);
      return {...task};
    });
  }

  const addComment = async () => {
    try {
      const commentData = {
        text: newComment.text,
        media: newComment.mediaPreview?.uuid ? newComment.mediaPreview : null
      };

      const { data } = await api.post(`/task/${id}/comment`, commentData);
      setTask(task => {
        task.comments.push(data);
        return { ...task };
      });

      setNewComment({ open: false, text: '', mediaPreview: null });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao adicionar comentário',
        text: error.message,
      });
    }
  };

  const removeComment = async (taskId, commentId) => {
    try {
      console.log(`Excluindo comentário com ID: ${commentId}`); // Para depuração

      if (!commentId) {
        throw new Error('Comentário ID está indefinido');
      }

      await api.delete(`/task/${taskId}/comment/${commentId}`);

      setTask(prevTask => ({
        ...prevTask,
        comments: prevTask.comments.filter(comment => comment.id !== commentId),
      }));

      Swal.fire({
        icon: 'success',
        title: 'Comentário excluído com sucesso!',
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao excluir comentário',
        text: error.message,
      });
    }
  };

  const handleUploadMedia = async (file) => {
    try {
      const reader = new FileReader();
      reader.onload = async () => {
        const fileBase64 = reader.result.split(",")[1];
        const contentType = file.type;
        const { uuid, filePath } = await uploadFile(file.name, `data:${contentType};base64,${fileBase64}`, (progress) => {
          console.log(`Progresso do upload: ${progress}%`);
        });

        const isImage = contentType.startsWith("image/");
        setNewComment((prev) => ({
          ...prev,
          mediaPreview: {
            uuid,
            filePath: filePath.startsWith('https://storage.hustapp.com/')
                ? filePath
                : `https://storage.hustapp.com/${filePath}`,
            isImage,
          },
        }));
      };
      reader.readAsDataURL(file);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao fazer upload',
        text: error.message,
      });
    }
  };

  const changeTask = async (id, params) => {
    await api.put(`/task/${id}`, params);
    setTask({...task, ...params});
    Swal.mixin({toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, timerProgressBar: true})
      .fire({icon: 'success', title: 'Tarefa atualizada!'});
  };

  const createTaskItem = async () => {
    const {data} = await api.post('/task', {title: 'Item para verificação', task: {id}});
    setTask(task => {
      task.subTasks.push(data);
      return {...task};
    })
  };

  useEffect(() => {
    if (open && id) {
      getTask();
      getUsers();
    }
    if (!open) setTask(defatultTaskData);
  }, [open]);

  const addRecipient = async () => {
    const usersTemp = {};
    users.forEach(user => {
      usersTemp[user.id_usuario] = user.nome;
    });
    const {value} = await Swal.fire({
      input: 'select',
      inputOptions: usersTemp,
      inputPlaceholder: 'Selecione o usuário',
      // inputValue: conexaoData.uuid,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      allowOutsideClick: false,
      inputValidator: value => value ? "" : "Selecione um usuario para atribuir a tarefa"
    });
    if(!value) return;

    await updateTask(id, {user: {id: value}});
  };

  const removeUser = async () => {
    const {value} = await Swal.fire({
      icon: "question",
      title: 'Deseja realmente remover o usuário responsável?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    });
    if (!value) return;
    await api.delete(`/task/${id}/user`);
    setTask(task => ({...task, user: null}));
  };

  const removeCalled = async idCalled => {
    const {value} = await Swal.fire({
      icon: "question",
      title: 'Deseja realmente remover o chamado?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    });
    if (!value) return;
    await api.delete(`/task/${id}/called/${idCalled}`);
    setTask(task => {
      task.calleds = task.calleds.filter(el => el.id !== Number(idCalled));
      return {...task};
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      // fullScreen
      classes={{ paper: classes.modalNew }}
    >
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <h4
            style={{
              fontFamily: 'NunitoRegular',
              fontSize: 28,
              marginBottom: 0,
              color: '#1A1926',
              fontWeight: 700,
              display: 'flex',
              alignItems: 'center',
              width: 'calc(100% - 50px)',
            }}
          >
            {/*Tarefa: <span style={{color: '#E94D3F'}}>{id}</span>*/}
            <Button
              onClick={() => {
                setEditModeTitle(!editModeTitle);
                if (editModeTitle) changeTask(id, { title: task.title });
              }}
              justIcon
              simple
              color={'primary'}
              style={{ padding: 0, margin: '-3px 5px 0px 5px', width: 20, height: 20, minWidth: 20 }}
            >
              <FaRegEdit />
            </Button>
            {editModeTitle ? (
              <CustomInput
                labelText={''}
                formControlProps={{ fullWidth: true, style: { margin: 0, padding: 0 } }}
                inputProps={{
                  value: task.title,
                  onChange: ({ target: { value } }) => setTask({ ...task, title: value }),
                }}
              />
            ) : (
              <span
                style={{
                  color: '#E94D3F',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {task.title}
              </span>
            )}
          </h4>
          <IconButton onClick={onClose} style={{ width: 30, height: 30, padding: 0 }}>
            <img src={Close} alt={'close'} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ overflowX: 'hidden' }}>
        <GridContainer>
          {/*<GridItem lg={12} md={12} sm={12} xs={12}>*/}
          {/*  */}
          {/*</GridItem>*/}
          <GridItem lg={8} md={8} sm={12} xs={12}>
            {/*<Tooltip title={'Marcar como completa'}>
              <Button
                justIcon
                color={'primary'}
                style={{width: 30, height: 30, minWidth: 30, padding: 0}}
                onClick={() => updateTask(id, {status: 'finished'}).then(onClose)}
              >
                <FaCheck/>
              </Button>
            </Tooltip>
            <hr/>*/}
            {/*<CustomInput
              labelText={'Título'}
              formControlProps={{fullWidth: true}}
              inputProps={{ value: task.title }}
            />*/}
            <strong>Descrição</strong>
            <Button
              onClick={() => {
                setEditMode(!editMode);
                if (editMode) {
                  changeTask(id, { description: task.description });
                }
              }}
              justIcon
              simple
              color={'primary'}
              style={{ padding: 0, margin: '-3px 0px 0px 5px', width: 15, height: 15, minWidth: 15 }}
            >
              <FaRegEdit />
            </Button>
            <br />
            {editMode ? (
              <Editor
                apiKey="ipt1ks61cuzfqgcp5hbwlwmqaw9iw1krq4w307tmfifjgu2p"
                onInit={(_evt, editor) => (editorRef.current = editor)}
                init={{
                  resize: false,
                  plugins: '',
                  language: 'pt_BR',
                  toolbar:
                    'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                  selector: 'textarea',
                  mergetags_list: [{ value: 'First.Name', title: 'First Name' }, { value: 'Email', title: 'Email' }],
                }}
                value={task.description || ''}
                onEditorChange={description => setTask({ ...task, description })}
              />
            ) : task.description ? (
              <div
                style={{ marginBottom: 0 }}
                dangerouslySetInnerHTML={{ __html: converterTextoWpp(task.description || '') }}
              />
            ) : (
              <span style={{ color: '#999999' }}>Digite a descrição da tarefa...</span>
            )}
            <hr />

            <span
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                fontWeight: 900,
                color: primaryColor[0],
              }}
              onClick={createTaskItem}
            >
              <FaPlusCircle />
              &nbsp;Item para Verificação
            </span>
            {!task.subTasks.length ? (
              <span style={{ color: '#999999' }}>
                Itens da lista de verificação não foram encontrados para esta tarefa
              </span>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CustomLinearProgress
                  color={'primary'}
                  value={(task.subTasks.filter(el => el.status === 'finished').length / task.subTasks.length) * 100}
                  variant={'determinate'}
                  height
                  style={{ width: '100%', marginBottom: 0, borderRadius: 10 }}
                />
                &nbsp;
                {((task.subTasks.filter(el => el.status === 'finished').length / task.subTasks.length) * 100).toFixed()}
                %
              </div>
            )}
            {task.subTasks.map(prop => {
              return (
                <div key={prop.id} style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={prop.status === 'finished'}
                    tabIndex={-1}
                    onClick={async () => {
                      const params = { status: prop.status === 'finished' ? 'pending' : 'finished' };
                      await api.put(`/task/${prop.id}`, params);
                      setTask(task => {
                        const keyTask = task.subTasks.map(prop1 => prop1.id).indexOf(prop.id);
                        task.subTasks[keyTask] = { ...task.subTasks[keyTask], ...params };
                        return { ...task };
                      });
                    }}
                    checkedIcon={
                      <ImRadioChecked2 className={classes.checkedIcon} style={{ borderRadius: '50%', padding: 2 }} />
                    }
                    icon={<IoRadioButtonOffSharp className={classes.uncheckedIcon} style={{ borderRadius: '50%' }} />}
                    classes={{ checked: classes.checked, root: classes.root }}
                    style={{ width: 38 }}
                  />
                  <span
                    style={{ width: '100%', textDecoration: prop.checked ? 'line-through' : 'none', color: '#555' }}
                  >
                    <CustomInput
                      labelText={''}
                      formControlProps={{ fullWidth: true, style: { margin: 0, padding: 0 } }}
                      inputProps={{
                        className: classes.removeMargin,
                        value: prop.title,
                        // underline: false,
                        onChange: ({ target: { value } }) => {
                          setTask(task => {
                            const keyTask = task.subTasks.map(prop1 => prop1.id).indexOf(prop.id);
                            task.subTasks[keyTask].title = value;
                            return { ...task };
                          });
                        },
                        onBlur: async () => {
                          const params = { title: prop.title };
                          await api.put(`/task/${prop.id}`, params);
                        },
                      }}
                    />
                    {/*{prop.title}*/}
                  </span>
                  {/*<div style={{width: 96, display: 'flex'}}> Quando Estão os 3 botões*/}
                  <div style={{ width: 38, display: 'flex' }}>
                    {/*<Tooltip title={'Converter para tarefa'}>
                      <Button color={'success'} simple justIcon round size={'sm'}>
                        <MdOutlineAddTask/>
                      </Button>
                    </Tooltip>
                    <Tooltip title={'Definir Responsável'}>
                      <Button color={'info'} simple justIcon round size={'sm'}>
                        <IoMdPersonAdd/>
                      </Button>
                    </Tooltip>*/}
                    <Tooltip title={'Excluir'}>
                      <Button color={'danger'} simple justIcon round size={'sm'} onClick={() => removeTask(prop.id)}>
                        <MdClose />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              );
            })}
            <hr />
            <Tabs
              value={'comments'}
              onChange={() => ''}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab value="comments" label="Comentários" />
              <Tab value="activity" label="Atividade" />
            </Tabs>
            <Accordion
              expanded={newComment.open}
              onChange={() => setNewComment({ open: !newComment.open, text: '' })}
              style={{ boxShadow: 'none' }}
              sx={{
                '& .MuiAccordion-region': { height: newComment.open ? 'auto' : 0 },
                '& .MuiAccordionDetails-root': { display: newComment.open ? 'block' : 'none' },
              }}
            >
              <AccordionSummary style={{ padding: 0 }}>
                <span
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 900,
                    color: primaryColor[0],
                  }}
                >
                  <FaCommentMedical />
                  &nbsp;Adicionar comentário
                </span>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>
                <div style={{ position: 'relative', width: '100%' }}>
                  <div
                      style={{
                        border: '1px solid #E0E0E0',
                        borderRadius: '12px',
                        padding: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        backgroundColor: '#F9F9F9',
                      }}
                  >
                    <textarea
                      placeholder="Escreva um comentário..."
                      value={newComment.text}
                      onChange={({ target: { value } }) => setNewComment({ ...newComment, text: value })}
                      onPaste={async event => {
                        const clipboardItems = event.clipboardData.items;
                        for (let item of clipboardItems) {
                          if (item.type.startsWith('image/')) {
                            const file = item.getAsFile();
                            const reader = new FileReader();

                            reader.onload = async () => {
                              const fileBase64 = reader.result;

                              const base64Clean = fileBase64.split(',')[1];
                              const { uuid, filePath } = await uploadFile(
                                file.name,
                                `data:image/png;base64,${base64Clean}`,
                              );

                              let cleanFilePath = filePath;
                              if (filePath.startsWith('https://storage.hustapp.com/')) {
                                cleanFilePath = filePath.replace(/^https:\/\/storage\.hustapp\.com\//, '');
                              }

                              setNewComment(prev => ({
                                ...prev,
                                mediaPreview: { uuid, filePath: `https://storage.hustapp.com/${cleanFilePath}` },
                              }));
                            };

                            reader.readAsDataURL(file);
                          }
                        }
                      }}
                      style={{
                        width: '100%',
                        border: '1px solid #CCCCCC',
                        borderRadius: '8px',
                        outline: 'none',
                        resize: 'none',
                        padding: '12px',
                        fontSize: '14px',
                        fontFamily: 'inherit',
                        backgroundColor: '#FFFFFF',
                      }}
                      rows={4}
                    />

                    {newComment.mediaPreview && (
                        <div
                            style={{
                              position: 'relative',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              backgroundColor: '#FFFFFF',
                              borderRadius: '8px',
                              overflow: 'hidden',
                              width: '100px',
                              height: '100px',
                              border: '1px solid #DDDDDD',
                              marginTop: '10px',
                            }}
                        >
                          <img
                              src={newComment.mediaPreview.filePath}
                              alt="Pré-visualização"
                              onClick={() => openImageView(newComment.mediaPreview.filePath)}
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                cursor: 'pointer',
                              }}
                          />
                          <Button
                              color="danger"
                              justIcon
                              size="sm"
                              onClick={() => setNewComment(prev => ({ ...prev, mediaPreview: null }))}
                              style={{
                                position: 'absolute',
                                top: '-8px',
                                right: '-8px',
                                borderRadius: '50%',
                                background: 'red',
                                color: 'white',
                                width: '20px',
                                height: '20px',
                              }}
                          >
                            <MdClose />
                          </Button>
                        </div>
                    )}
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
                    <Button
                        color={'hust'}
                        style={{
                          height: 40,
                          padding: '0 20px',
                          borderRadius: '8px',
                          fontWeight: 'bold',
                          backgroundColor: '#4CAF50',
                          color: '#FFF'
                        }}
                        onClick={addComment}
                    >
                      Comentar
                    </Button>
                    <Button
                        color={'hust'}
                        style={{
                          height: 40,
                          padding: '0 20px',
                          borderRadius: '8px',
                          fontWeight: 'bold',
                          backgroundColor: '#2196F3',
                          color: '#FFF'
                        }}
                        onClick={() => setUploadModalOpen(true)}
                    >
                      <FaPaperclip />
                    </Button>

                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            {task.comments
              .sort((a, b) => (a.date > b.date ? -1 : a.date < b.date ? 1 : 0))
              .map(prop => {
                return (
                  <div key={prop.id} style={{display: 'flex', flexDirection: 'column', marginTop: 20, padding: '15px', backgroundColor: '#f9f9f9', borderRadius: '10px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)',}}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <img
                        src={getFotoLink(prop.user.profilePicture)}
                        alt={prop.user.name}
                        style={{ width: 35, height: 35, borderRadius: '50%' }}
                      />
                      <div style={{ marginLeft: 10, flex: 1 }}>
                        <strong style={{ color: '#1A1926' }}>{prop.user.name}</strong>&nbsp;
                        <small>{convertDate(prop.date, false, true, false, true)}</small>
                      </div>
                      <Tooltip title={'Excluir Comentário'}>
                        <Button
                            style={{margin: 0, width: 30, height: 30, minWidth: 30, padding: 0}}
                            justIcon round simple color={'danger'} onClick={() => removeComment(task.id, prop.id)}
                            size={'sm'}
                        >
                          <IoClose/>
                        </Button>
                      </Tooltip>
                    </div>

                    <div style={{ marginBottom: '10px', fontSize: '14px', color: '#555', whiteSpace: 'pre-wrap' }}>{prop.text}</div>

                    {prop.medias?.length > 0 && (
                        <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', alignItems: 'center' }}>
                          {prop.medias.map((media, index) => {
                            const fileUrl = media.fileData.name.startsWith('http')
                                ? media.fileData.name
                                : `https://storage.hustapp.com/midias/${media.uuid}`;
                            const isImage = /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(media.fileData.name);

                            return (
                                <div key={index} style={{ marginTop: '10px', display: 'inline-block', textAlign: 'center' }}>
                                  {isImage ? (
                                      <img
                                          src={fileUrl}
                                          alt={`media-${index}`}
                                          style={{
                                            maxWidth: '150px',
                                            maxHeight: '150px',
                                            objectFit: 'cover',
                                            borderRadius: '10px',
                                            cursor: 'pointer',
                                          }}
                                          onClick={() => openImageView(fileUrl)}
                                      />
                                  ) : (
                                      <a
                                          href={"#"}
                                          onClick={async e => {
                                            e.preventDefault();
                                            console.log(media.fileData);
                                            await downloadFileAsBlob(await fetchAsBlob(fileUrl), media.fileData.name)
                                          }
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            display: 'block',
                                            textDecoration: 'none',
                                            color: '#007bff',
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                          }}
                                      >
                                        🔗 {media.fileData.name}
                                      </a>
                                  )}
                                </div>
                            );
                          })}
                        </div>
                    )}

                  </div>
                  );
                })}

          </GridItem>
          <GridItem
            lg={4}
            md={4}
            sm={12}
            xs={12}
            style={{ background: '#EEE', borderRadius: 15, boxShadow: '0 0 10px -5px #666' }}
          >
            <br />
            <strong>Informações da Tarefa</strong> <br />
            <small>Criado em 13/01/2021 17:23:07</small>
            <hr style={{ borderTop: '1px solid #CCC' }} />
            {[
              { icon: FaRegStarHalfStroke, label: `Status: ${TaskStatusLabel[task.status]?.label}` },
              {
                icon: FaRegCalendarPlus,
                label: (
                  <>
                    Início:&nbsp;
                    <Datetime
                      timeFormat={true}
                      onChange={e =>
                        typeof e === 'object'
                          ? changeTask(id, { dateStart: e.toDate() })
                          : e.length === 10
                          ? isValidDate(
                              new Date(
                                e
                                  .split('/')
                                  .reverse()
                                  .join('-'),
                              ),
                            )
                            ? changeTask(id, {
                                dateStart: new Date(
                                  e
                                    .split('/')
                                    .reverse()
                                    .join('-'),
                                ),
                              })
                            : ''
                          : ''
                      }
                      value={new Date(task.dateStart)}
                      inputProps={{ placeholder: 'Data de início', style: { background: 'none' } }}
                    />
                  </>
                ),
              },
              {
                icon: FaRegCalendarCheck,
                label: (
                  <>
                    Limite:&nbsp;
                    <Datetime
                      timeFormat={true}
                      onChange={e =>
                        typeof e === 'object'
                          ? changeTask(id, { dateDue: e.toDate() })
                          : e.length === 10
                          ? isValidDate(
                              new Date(
                                e
                                  .split('/')
                                  .reverse()
                                  .join('-'),
                              ),
                            )
                            ? changeTask(id, {
                                dateDue: new Date(
                                  e
                                    .split('/')
                                    .reverse()
                                    .join('-'),
                                ),
                              })
                            : ''
                          : ''
                      }
                      value={task.dateDue ? new Date(task.dateDue) : ''}
                      inputProps={{ placeholder: 'Data final', style: { background: 'none' } }}
                    />
                  </>
                ),
              },
              { icon: IoMdFlash, label: `Prioridade: ${TaskPriorityLabel[task.priority]?.label}` },
            ].map((prop, key) => (
              <span key={key} style={{ display: 'flex', alignItems: 'center' }}>
                <prop.icon color={'#e94d3f'} />
                &nbsp;{prop.label}
              </span>
            ))}
            {task.type === 'lead' ? (
              <Fragment>
                <hr style={{ borderTop: '1px solid #CCC' }} />
                {[
                  { slug: 'unique', title: 'Único', icon: MdAttachMoney, value: task.valueUnique || 0 },
                  { slug: 'recurring', title: 'Recorrente', icon: FaMoneyBillTrendUp, value: task.valueRecurring || 0 },
                ].map(prop => (
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <prop.icon color={'#e94d3f'} />
                    &nbsp;Valor {prop.title}: &nbsp;
                    {editValue.slug === prop.slug ? (
                      <Fragment>
                        <input
                          defaultValue={prop.value}
                          type={'number'}
                          style={{ background: 'none', borderRadius: 5, border: '1px solid #555', width: 60 }}
                          onChange={({ target: { value } }) => setEditValue({ ...editValue, value: Number(value) })}
                        />
                        &nbsp;
                        <FaSave
                          color={'#e94d3f'}
                          style={{ cursor: 'pointer' }}
                          onClick={async () => {
                            if (editValue.value !== prop.value) {
                              if (editValue.value < 0)
                                return Swal.fire({
                                  icon: 'warning',
                                  title: 'O valor não pode ser menor que zero.',
                                });
                              const params = {};
                              if (prop.slug === 'unique') params.valueUnique = editValue.value;
                              if (prop.slug === 'recurring') params.valueRecurring = editValue.value;
                              updateTask(task.id, params); // TODO - Se der problema colocar "await"
                              setTask({ ...task, ...params });
                            }
                            setEditValue({ slug: '', value: 0 });
                          }}
                        />
                      </Fragment>
                    ) : (
                      <span
                        style={{ cursor: 'pointer' }}
                        // onClick={changeLeadValue.bind(this, 'unique')}
                        onClick={() => setEditValue({ slug: prop.slug, value: prop.value })}
                      >
                        {formatoDinheiro(prop.value)}
                      </span>
                    )}
                  </span>
                ))}
              </Fragment>
            ) : (
              ''
            )}
            {/*<span style={{display: 'flex', alignItems: "center"}}><FaRegBell color={'#e94d3f'}/>&nbsp;Lembretes</span>*/}
            <hr style={{ borderTop: '1px solid #CCC' }} />
            {/*<IoTicketOutline/> Chamados <p/>*/}
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <IoTicketOutline color={'#e94d3f'} />
              &nbsp;Chamados
            </span>
            <p />
            <div style={{ display: 'flex' }}>
              {task?.calleds?.map((called, k) => {
                const {
                  id,
                  contact: { name, idWhatsapp },
                } = called;
                const [phone] = idWhatsapp.split('@');
                return (
                  <ViewUserActions
                    name={called.id + ' - ' + (name || phone)}
                    profilePicture={called.contact.profilePicture}
                    showRemove
                    onDelete={() => removeCalled(id)}
                  />
                );
              })}
            </div>
            <hr style={{ borderTop: '1px solid #CCC' }} />
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <IoPersonOutline color={'#e94d3f'} />
              &nbsp;Destinatário
            </span>
            <p />
            {task.user ? (
              <ViewUserActions
                name={task.user.name}
                profilePicture={task.user.profilePicture}
                showRemove
                onDelete={removeUser}
              />
            ) : (
              <Tooltip title={'Definir Responsável'}>
                <Button color={'primary'} justIcon size={'sm'} round onClick={addRecipient}>
                  <FaPlus />
                </Button>
              </Tooltip>
            )}
            <hr style={{ borderTop: '1px solid #CCC' }} />
          </GridItem>
        </GridContainer>

        {viewImage && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000,
            }}
            onClick={closeImageView}
          >
            <img
              src={viewImage}
              alt="Preview"
              style={{
                maxWidth: '90%',
                maxHeight: '90%',
                borderRadius: '10px',
              }}
            />
            <Button
              style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                backgroundColor: 'white',
                borderRadius: '50%',
                cursor: 'pointer',
              }}
              onClick={closeImageView}
            >
              <MdClose />
            </Button>
          </div>
        )}
        {uploadModalOpen && (
            <UploadMedia
                open={uploadModalOpen}
                onClose={() => setUploadModalOpen(false)}
                onUpload={handleUploadMedia}
            />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TaskViewer;